import React, { useEffect, useLayoutEffect } from "react";
import * as S from "./styles";

function ListDocs() {
  return (
    <S.Container>
      <S.GroupText>
        <S.TopPage>
          <S.LogoHotel alt="Logo Hotel" />
          <S.Title>Informações Gerais</S.Title>
        </S.TopPage>

        <S.Box href="guia-virtual-de-hospedagem/3Pinheiros_2025_diretorio_1080x1920-3.pdf">
          <S.DownloadIcon />
          <h3>Guia Virtual de Hospedagem</h3>
        </S.Box>
        <S.Box href="cardapios/cardapio_frigobar.pdf">
          <S.DownloadIcon />
          <h3>Cardápio Frigobar</h3>
        </S.Box>
      </S.GroupText>
    </S.Container>
  );
}

export default ListDocs;
