import styled from "styled-components";
import Logo from "../../assets/logo-vertical.png";
import Download from "../../assets/download.png";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #1d4550;
  height: 100%;
`;

export const TopPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 100%;
  background: #2b8378;
  margin-bottom: 30px;
`;

export const GroupText = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  background: #fff;
  padding: 20px;
  border: 2px solid #c5c5c5;
`;

export const LogoHotel = styled.img.attrs({
  src: Logo,
})`
  max-width: 80px;
  margin: 0 auto 15px auto;
  height: auto;
`;
export const DownloadIcon = styled.img.attrs({
  src: Download,
})`
  max-width: 15px;
  height: auto;
`;

export const Title = styled.h2`
  max-width: 300px;
  font-family: "GilroySemiBold";
  font-size: 15px;
  color: #fff;
  margin: 0 auto 50px auto;
  text-transform: uppercase;
  text-align: center;
`;

export const Box = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px 15px 20px 15px;
  text-decoration: none;
  column-gap: 15px;
  cursor: pointer;
  border-bottom: 1px dotted #c5c5c5;
  h3 {
    font-family: "GilroyRegular";
    font-size: 15px;
    color: #000;
  }
`;
